<template>
    <div class="add_update_role">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :disabled="isCheck" :label-width="formLabelWidth">
                    <el-form-item label="车牌号:" prop="vehicleId">
                        <div @click="carSelect">
                            <el-input v-model="diaform.plateNum" placeholder="请选择车牌" readonly></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="所属机构:" prop="orgNa">
                        <el-input v-model="diaform.orgNa" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="司机姓名:" prop="driverIds">
                        <el-input v-model="diaform.driverNames" placeholder="请选择司机" readonly @focus="siteSelect"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="!isCheck">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save('dialogForm')">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
        <cardriver-select ref="siteSelectDialog" @choose="chooseDriver"></cardriver-select>
        <linecar-select ref="carSelectDialog" @selected="updateCar"></linecar-select>
    </div>
</template>
<script>
import cardriverSelect from '@/components/pages/admin/resourcesAllocation/carDriver/cardriverSelectbatch'
import linecarSelect from '@/components/pages/admin/resourcesAllocation/carDriver/carSelect'

export default {
  data() {
    return {
      type: '',
      isCheck: false,
      title: '',
      diaform: {
        vehicleId: '',
        plateNum: '',
        orgNa:'',
        vOrgId:'',
        num:'',
        driverIds: '',
        driverNames:'',
      },
      chooseList:[],
      rules: {
        vehicleId: [
          {
            required: true,
            message: '请选取车辆',
            trigger: ['blur', 'change']
          }
        ],
        driverIds: [
          {
            required: true,
            message: '请选取司机',
            trigger: ['blur', 'change']
          }
        ],
      },
      dialogVisible: false,
      formLabelWidth: '100px'
    }
  },
  components: {
    cardriverSelect,
    linecarSelect
  },
  activated() {},
  created() {},
  watch: {
    type: function(val) {
      let t = ''
      let isCheck = false
      switch (val) {
        case 'add':
          t = '新增司机分配'
          break
        case 'update':
          t = '修改司机分配'
          break
        case 'view':
          t = '查看司机分配'
          isCheck = true
          break
        default:
          t = '新增司机分配'
      }
      this.isCheck = isCheck
      this.title = t
    },
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('dialogForm')
      }
    },
  },
  methods: {
    queryByVehicleId(){
        let url = '/base/vehicle/driver/queryByVehicleId'
        let option = {
            vehicleId:this.diaform.vehicleId,
        }
        this.$http.post(url,option).then(({detail})=>{
            this.chooseList = detail.map(item=>{
                return {
                    driverId:item.driverId,
                    cName:item.driverInfo.cName,
                }
            })
            this.diaform.driverIds = detail.map(item=>item.driverId).join(',')
            this.diaform.driverNames = detail.map(item=>item.driverInfo.cName).join(',')
        })
    },
    init(option, type) {
      this.type = type ? type : 'add'
      this.dialogVisible = true
      if (option) {
        this.$nextTick(()=>{
            this.diaform.vehicleId = option.vehicleInfo.vehicleId
            this.diaform.plateNum = option.vehicleInfo.plateNum
            this.diaform.orgNa = option.sysOrg.orgNa
            this.diaform.vOrgId = option.sysOrg.orgId
            this.diaform.num = option.sysOrg.num
            this.queryByVehicleId()
        })
      }
    },
    // 车辆选择
    carSelect() {
      this.$refs.carSelectDialog.init()
    },
    updateCar(car) {
      console.log(car)
      this.diaform.plateNum = car.plateNum
      this.diaform.vehicleId = car.vehicleId
      this.diaform.orgNa = car.orgNa
      this.diaform.vOrgId = car.orgId
      this.diaform.num = car.num
    },
    // 司机选择
    siteSelect() {
        this.$refs.siteSelectDialog.init(this.chooseList)
    },
    chooseDriver(list) {
        this.chooseList = list
        this.diaform.driverIds = list.map(item=>item.driverId).join(',')
        this.diaform.driverNames = list.map(item=>item.cName).join(',')
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.diaform.plateNum = ''
      this.diaform.driverNames = ''
      this.diaform.vOrgId = ''
      this.diaform.num = ''
      this.chooseList = []
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let url = '/base/vehicle/driver/adjust'
        //   if (this.type == 'add') {
        //     url = url + 'addDrivers'
        //   } else {
        //     url = url + 'updateDriver'
        //   }
          let option = this.diaform.driverIds.split(',').map(one=>{
                return {
                    vehicleId:this.diaform.vehicleId,
                    plateNum:this.diaform.plateNum,
                    vOrgId:this.diaform.vOrgId,
                    num:this.diaform.num,
                    driverId:one,
                }
          })
          this.$http.post(url, option,{isRequestParam: false}).then(res => {
            this.$message({
              type: 'success',
              message: res.msg
            })
            this.dialogVisible = false
            this.$emit('updateList')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
